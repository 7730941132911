import {useTranslation} from "gatsby-plugin-react-i18next";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid, Hidden,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Typography
} from "@material-ui/core";
import React, {useState} from "react"
import styled from "@emotion/styled"
import {FaExternalLinkAlt} from "@react-icons/all-files/fa/FaExternalLinkAlt"
import {MdVisibilityOff} from "@react-icons/all-files/md/MdVisibilityOff"
import {MdVisibility} from "@react-icons/all-files/md/MdVisibility"
import {GrowGrid, UnstyledExternalLink} from "../../../components/Shared"
import {TransMdTypo} from "../../../components/i18n/TransMdTypo"
import {graphql, useStaticQuery} from "gatsby"
import {File, Maybe, Query} from "../../../../graphql-types"
import {GatsbyImage} from "gatsby-plugin-image"


const CircleAvatar = styled(Avatar)(({theme}) => ({
  border: `solid 2px ${theme.palette.text.primary}`
}))

const ReductionCode = (props: { desc: string, code?: string, active?: boolean }) => {
  const [show, setShow] = useState(false)
  const handleToggleShow = () => {
    if (props.active !== false)
      setShow(!show)
  }
  return <FormControl css={{width: "300px"}} variant="outlined" size={"small"}>
    <InputLabel htmlFor="outlined-adornment-password">{props.desc}</InputLabel>
    <OutlinedInput
      id="outlined-adornment-password"
      type={show ? "text" : "password"}
      value={props.code}
      endAdornment={props.active !== false &&
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleToggleShow}
          edge="end"
        >
          {show ? <MdVisibilityOff/> : <MdVisibility/>}
        </IconButton>
      </InputAdornment>
      }
      label={props.desc}
    />
  </FormControl>
}

export const ReductionsCard = () => {
  const {t} = useTranslation()
  const [state, setState] = useState({show: false, title: "", desc: "", url: ""})

  const reductions = useStaticQuery<Query>(graphql`
    query {
          allReductionsYaml {
            edges {
              node {
                id
                name
                shortdescription
                longdescription
                link
                reduction
                code
                active
                avatar {
                  childImageSharp {
                    gatsbyImageData(width: 50)
                  }
                }
              }
            }
          }
        }
  `).allReductionsYaml.edges.map(e => e.node)

  const showShopPopup = (title: string, desc: string, url: string) => {
    setState({show: true, title, desc, url})
  }
  const handlePopupClose = () => setState({...state, show: false})

  return <React.Fragment>
    <Dialog
      maxWidth={"lg"}
      open={state.show}
      onClose={handlePopupClose}
    >
      <DialogTitle id="alert-dialog-title">
        {state.title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText css={{marginTop: "1em"}}>
          <TransMdTypo>{t(state.desc)}</TransMdTypo>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button href={state.url} target={"_blank"}>{t("patreon^Visit")}</Button>
        <Button onClick={handlePopupClose} autoFocus>
          {t("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={"h3"}>{t("patreon^My discounts")}</Typography>
            <Typography>{t("patreon^Discount presentation")}</Typography>
          </Grid>
          {reductions.map(reduction => {
            const reductionCode = <ReductionCode desc={reduction.active ? reduction.reduction! : t("patreon^Available soon!")}
                                             code={reduction.active ? reduction.code! : "XOXOSOON"} active={reduction.active!}/>
            return <Grid item xs={12}>
              <Grid container spacing={2} alignContent={"center"} alignItems={"center"} wrap={"nowrap"}>
                <Grid item>

                  <CircleAvatar src={reduction.avatar!.childImageSharp!.gatsbyImageData.images.fallback.src}
                                variant={"circle"}/>
                </Grid>
                <Grid item>
                  <Link color={"textPrimary"} css={{cursor: "pointer"}}
                        onClick={() => {
                          reduction.longdescription && showShopPopup(reduction.name!, reduction.longdescription, reduction.link!)
                        }}>{reduction.name!}</Link>
                  &nbsp;<UnstyledExternalLink href={reduction.link!}
                                              target={"_blank"}><FaExternalLinkAlt/></UnstyledExternalLink>
                  <Typography variant={"subtitle1"}>{t(reduction.shortdescription!)}</Typography>
                  <p></p>
                </Grid>
                <GrowGrid item>
                </GrowGrid>
                <Hidden smDown>
                  <Grid item>
                    {reductionCode}
                  </Grid>
                </Hidden>
              </Grid>
              <Hidden mdUp>
                <Grid item css={{marginTop: "1em"}}>
                  {reductionCode}
                </Grid>
              </Hidden>
            </Grid>
          })}
        </Grid>
      </CardContent>
    </Card>
  </React.Fragment>

}
